<template>
  <div class="main">
    <!-- 头部 -->
    <top></top>
    <!-- 导航 -->
    <navigation></navigation>
    
    <div class="breadcrumb">
       <div class="w">
		   <div class="icon"><i class="el-icon-s-home"></i></div>
		   <el-breadcrumb separator-class="el-icon-arrow-right">
		     <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
		     <el-breadcrumb-item>{{typeName}}</el-breadcrumb-item>
		   </el-breadcrumb>
	   </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="title">
        <img src="../assets/img/circle.png" alt="" />
        <div class="text">{{typeName}}</div>
      </div>
      <ul class="list">
        <li v-for="li in tableData" :key="li.id" class="item" @click="toDetail(li.id)">
          <div class="headline">· {{li.title}}</div>
          <div class="date">{{li.createTime}}</div>
        </li>
      </ul>
      <div class="block">
        <el-pagination
          :current-page.sync="pageIndex"
          :total="totalElements"
          :page-sizes="pageSizeList"
          @current-change="changePage"
          @size-change="pageSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>

    <!-- 底部 -->
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from '../components/Bottom.vue';
import Navigation from "../components/Navigation.vue";
import Top from "../components/Top.vue"
import newsApi from "@/api/news";

export default {
  components:{
    Navigation,
    Top,
    Bottom
  },
  data() {
    return {
      typeId: this.$route.meta.typeId,
      typeName: this.$route.meta.title,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);
      formData.append("typeId", self.typeId);
      // formData.append("title", self.queryModel.title);

      newsApi.pageList(formData).then(function (response) {
        var jsonData = response.data.data;

        self.tableData = jsonData.data;
        self.totalPages = jsonData.totalPages;
        self.totalElements = jsonData.recordsTotal;
      })
      .catch((error) => {
        self.$message.error("新闻获取数据发生错误！");
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    toDetail(id) {
      let pathInfo = this.$router.resolve({
      path:'/detail',
        query:{
            id: id
        }
      })
      window.open(pathInfo.href, '_blank');
    }
  },
  mounted: function () {
    this.changePage(1);
  }
};
</script>

<style lang="scss" scoped>
.main {
  min-width: 1139px;
  width: 100%;
  
}

.w{
	width: 1220px;
	margin: 0 auto;
	display: flex;
	height: 100%;
}
.breadcrumb {
	
  display: flex;
  background-color: rgba(233, 234, 236, 1) !important;
  background: url(../assets/img/breadcrumb.png);
  background-repeat: no-repeat;
  background-size: 43.6% 93.2%;
  background-position: 90% 100%;
  padding: 48px 0px;
  line-height: 24px;
  margin-bottom: 0;
  .icon {
    font-size: 24px;
    color: #1f4a99;
  }
  .el-breadcrumb {
    line-height: 24px;
    margin-left: 4px;
	font-family: 'Regular';
  }
  /deep/.el-breadcrumb__inner.is-link {
    color: #1f4a99 !important;
  }
}

// 内容
.content {
	max-width: 976px;
  margin: 40px auto;
  min-height: calc(100vh - 150px);
  .title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(187, 187, 187, 1);
    padding-bottom: 12px;
	font-family: 'SemiBold';
    img {
      width: 8px;
      height: 8px;
      margin-right: 11px;
    }
    .text {
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
    }
  }
  .list {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
	font-family: 'Regular';
    .item {
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      .headline {
        max-width: 434px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .block {
    
	display: flex;
	justify-content: center;
    /deep/.el-pager li.active {
      border: 1px solid rgba(232, 232, 232, 1) !important;
      color: #777777;
    }
    /deep/.el-pager li {
      color: #777777;
    }
  }
}


</style>
